import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';

import { createStore } from 'redux'
import { Provider } from 'react-redux'
import rootReducer from './redux/reducers';
import { toast } from 'react-toastify';

import './assets/boxicons-2.0.7/css/boxicons.min.css'
import './assets/css/index.css'
import './assets/css/theme.css'
import './assets/css/card.css'
import './assets/css/filtros.css'
import './assets/css/vista.css'
import './assets/css/modal.css'

import Layout from './components/layout/Layout'
const store = createStore(
  rootReducer
)

toast.configure({
  autoClose: 3000,
  draggable: false,
  closeButton: false,
  draggablePercent: 100,
  progressClassName: 'ourbar',
  position: 'top-left'
});

document.title = 'Entretenimiento';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
      <React.StrictMode>
        <Layout />
    </React.StrictMode>
  </Provider>
  
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
