import React from "react";
import "./sidebar.css";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import TopNav from "../topnav/TopNav";
import ResponsiveMenu from "../responsivemenu/ResponsiveMenu";
import { MountainSun } from "../iconos/Iconos";
import { useListMainMenuAll } from "../../hooks/useListMainMenuAll";
import { useListPermisos } from "../../hooks/useListPermisos.js";

const SidebarItem = (props) => {
  const active = props.active ? "active" : "";
  const prm = useListPermisos();

  return (
    <div className="sidebar__item" key={props.id}>
      {props.route !== "" ? (
        <Link to={props.route} key={`${props.id}`}>
          <div className={`sidebar__item-inner ${active}`}>
            {props.icono && <i className={props.icono} />}
            <span>{props.title}</span>
          </div>
        </Link>
      ) : (
        <div className={`sidebar__item-inner ${active}`}>
          {props.icono && <i className={props.icono} />}
          <span>{props.title}</span>
        </div>
      )}

      {props.sublist.length > 0 && (
        <div className="sublist">
          {props.sublist.map((item, index) => (
            prm.listado.includes(item.id) &&
              <Link to={item.route} key={`${item.id}`}>
                <SidebarSubItem
                  title={item.display_name}
                  sublist={item.sublist ? item.sublist : []}
                  id={item.id}
                />
              </Link>
          ))}
        </div>
      )}
    </div>
  );
};

const SidebarSubItem = (props) => {
  return (
    <div className="sidebar__subitem" key={`${props.id}`}>
      <div className={`sidebar__subitem-inner`}>
        {props.icono && <i className={props.icono} />}
        <span>{props.title}</span>
      </div>
      {props.sublist.length > 0 && (
        <div className="sublist">
          {props.sublist.map((item, index) => (
            <SidebarItem
              title={item.name}
              sublist={item.sublist ? item.sublist : []}
              route={item.route}
              id={item.id}
            />
          ))}
        </div>
      )}
    </div>
  );
};

const Sidebar = (props) => {
  const location = useLocation();
  const { listado } = useListMainMenuAll();
  const prm = useListPermisos();
  
  const activeItem = listado.findIndex(
    (item) => item.firstMenu === location.pathname.split("/")[1]
  );

  return (
    <div className="sidebar">
      <div className="itemsMenu">
        <div className="sidebar__logo">
          <Link to={"/"} key={`logoCRM`}>
            <div className="logoCRM">
              <div>
                CR
                <MountainSun color="var(--main-color)" />
              </div>
              <div>Lyan</div>
            </div>
          </Link>
        </div>
        {listado &&
          listado.map(
            (item, index) =>
              prm.listado.includes(item.id) && (
                <SidebarItem
                  title={item.display_name}
                  icono={item.icono}
                  active={index === activeItem}
                  sublist={item.sublist ? item.sublist : []}
                  route={item.route}
                  id={item.id}
                  key={item.id}
                />
              )
          )}
      </div>
      <div className="menuUser">
        <TopNav />
      </div>
      <div className="iconMenu">
        <ResponsiveMenu />
      </div>
    </div>
  );
};

export default Sidebar;
