import { useEffect, useState } from "react";
import { listJson } from "../redux/routes/index.jsx";
import { useSelector, useDispatch } from "react-redux";
import UserAction from "../redux/actions/UserAction.js";

export const useListMainMenuAll = () => {
  const [listado, setListado] = useState([]);
  const dispatch = useDispatch();
  const userReducer = useSelector((state) => state.UserReducer);

  useEffect(() => {
    if (userReducer.menu === undefined) {
      listJson("permisostodoMenuOth").then((output) =>
        dispatch(UserAction.getMenu(output.data))
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    if (userReducer.menu !== undefined) {
      setListado(userReducer.menu);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userReducer.menu]);

  return {
    listado
  };
};