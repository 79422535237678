import React, { lazy } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./layout.css";
import AllRoutes from "../Routes";

const Dashboard = lazy(() => import("../../pages/dashboard/Dashboard"));

const Compras = lazy(() => import("../../pages/compras/listado/Compras"));
const ComprasNuevo = lazy(() =>
  import("../../pages/compras/listado/ComprasNuevo.jsx")
);
const ComprasEdit = lazy(() =>
  import("../../pages/compras/listado/ComprasEdit.jsx")
);

const Tiendas = lazy(() => import("../../pages/compras/tiendas/Tiendas.jsx"));
const TiendasNuevo = lazy(() =>
  import("../../pages/compras/tiendas/TiendasNuevo.jsx")
);
const TiendasEdit = lazy(() =>
  import("../../pages/compras/tiendas/TiendasEdit.jsx")
);

const CC = lazy(() => import("../../pages/compras/cc/CC.jsx"));
const CCNuevo = lazy(() => import("../../pages/compras/cc/CCNuevo.jsx"));
const CCEdit = lazy(() => import("../../pages/compras/cc/CCEdit.jsx"));

const Generos = lazy(() => import("../../pages/settings/generos/Generos"));
const GenerosNuevo = lazy(() =>
  import("../../pages/settings/generos/GenerosNuevo")
);
const GenerosEdit = lazy(() =>
  import("../../pages/settings/generos/GenerosEdit")
);

const Listados = lazy(() => import("../../pages/settings/listados/Listados"));
const ListadosNuevo = lazy(() =>
  import("../../pages/settings/listados/ListadosNuevo")
);
const ListadosEdit = lazy(() =>
  import("../../pages/settings/listados/ListadosEdit")
);

const Tipos = lazy(() => import("../../pages/settings/tipos/Tipos"));
const TiposNuevo = lazy(() => import("../../pages/settings/tipos/TiposNuevo"));
const TiposEdit = lazy(() => import("../../pages/settings/tipos/TiposEdit"));

const Links = lazy(() => import("../../pages/settings/links/Links.jsx"));
const LinksEdit = lazy(() =>
  import("../../pages/settings/links/LinksEdit.jsx")
);

const Artistas = lazy(() => import("../../pages/musica/artistas/Artistas"));
const ArtistasNuevo = lazy(() =>
  import("../../pages/musica/artistas/ArtistasNuevo")
);
const ArtistasEdit = lazy(() =>
  import("../../pages/musica/artistas/ArtistasEdit")
);

const Listado = lazy(() => import("../../pages/musica/listado/Listado"));
const ListadoNuevo = lazy(() =>
  import("../../pages/musica/listado/ListadoNuevo")
);
const ListadoEdit = lazy(() =>
  import("../../pages/musica/listado/ListadoEdit")
);

const Scan = lazy(() => import("../../pages/contenido/scan/Scan"));
const ScanNuevo = lazy(() => import("../../pages/contenido/scan/ScanNuevo"));
const ScanEdit = lazy(() => import("../../pages/contenido/scan/ScanEdit"));

const Autores = lazy(() => import("../../pages/contenido/autores/Autores"));
const AutoresNuevo = lazy(() =>
  import("../../pages/contenido/autores/AutoresNuevo")
);
const AutoresEdit = lazy(() =>
  import("../../pages/contenido/autores/AutoresEdit")
);

const Random = lazy(() => import("../../pages/random/Listado.jsx"));
const RandomNuevo = lazy(() => import("../../pages/random/ListadoNuevo.jsx"));
const RandomEdit = lazy(() => import("../../pages/random/ListadoEdit.jsx"));

const Contenido = lazy(() => import("../../pages/contenido/listado/Listado"));
const ContenidoNuevo = lazy(() =>
  import("../../pages/contenido/listado/ListadoNuevo")
);
const ContenidoEdit = lazy(() =>
  import("../../pages/contenido/listado/ListadoEdit")
);

const Diarias = lazy(() => import("../../pages/compras/diarias/Diarias"));
const DiariasNuevo = lazy(() =>
  import("../../pages/compras/diarias/DiariasNuevo")
);

const Herramientas = lazy(() => import("../../pages/settings/herramientas/Herramientas"));
const HerramientasNuevo = lazy(() => import("../../pages/settings/herramientas/HerramientasNuevo"));
const HerramientasEdit = lazy(() => import("../../pages/settings/herramientas/HerramientasEdit"));

const Colores = lazy(() => import("../../pages/settings/colores/Colores"));
const ColoresNuevo = lazy(() => import("../../pages/settings/colores/ColoresNuevo"));
const ColoresEdit = lazy(() => import("../../pages/settings/colores/ColoresEdit"));

const Usuarios = lazy(() => import("../../pages/settings/usuarios/Usuarios"));
const UsuariosNuevo = lazy(() => import("../../pages/settings/usuarios/UsuariosNuevo"));
const UsuariosEdit = lazy(() => import("../../pages/settings/usuarios/UsuariosEdit"));

const Permiso = lazy(() => import("../../pages/settings/permisos/Permiso"));
const PermisoNuevo = lazy(() => import("../../pages/settings/permisos/PermisoNuevo"));
const PermisoEdit = lazy(() => import("../../pages/settings/permisos/PermisoEdit"));

const Trello = lazy(() => import("../../pages/settings/trello/Trello"));

const NotFound = lazy(() => import("../../pages/NotFound"));

const Layout = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<AllRoutes />}>
          <Route index element={<Dashboard />} />
          <Route path="compras">
            <Route path="listado" index element={<Compras tipo={"actu"}/>} />
            <Route path="listado/nuevo" index element={<ComprasNuevo />} />
            <Route path="listado/:id" index element={<ComprasEdit />} />

            <Route path="tiendas" index element={<Tiendas />} />
            <Route path="tiendas/nuevo" index element={<TiendasNuevo />} />
            <Route path="tiendas/:id" index element={<TiendasEdit />} />

            <Route path="centrocomercial" index element={<CC />} />
            <Route path="centrocomercial/nuevo" index element={<CCNuevo />} />
            <Route path="centrocomercial/:id" index element={<CCEdit />} />

            <Route path="diarias" index element={<Diarias />} />
            <Route path="diarias/nuevo" index element={<DiariasNuevo />} />

            <Route path="proximos" index element={<Compras tipo={"prxm"}/>} />
            <Route path="proximos/nuevo" index element={<ComprasNuevo />} />
            <Route path="proximos/:id" index element={<ComprasEdit />} />
          </Route>
          <Route path="mangas">
            <Route
              path="listado"
              index
              element={<Contenido tipo="tpmng" titulo="Mangas" />}
            />
            <Route
              path="listado/nuevo"
              index
              element={<ContenidoNuevo tipo="tpmng" />}
            />
            <Route
              path="listado/:id"
              index
              element={<ContenidoEdit tipo="tpmng" />}
            />
            <Route path="scans" index element={<Scan />} />
            <Route path="scans/nuevo" index element={<ScanNuevo />} />
            <Route path="scans/:id" index element={<ScanEdit />} />
            <Route
              path="random"
              index
              element={<Random tipo={"tpmng"} nombre={"Mangas"} />}
            />
            <Route
              path="random/nuevo"
              index
              element={<RandomNuevo tipo={"tpmng"} />}
            />
            <Route path="random/:id" index element={<RandomEdit tipo={"tpmng"}/>} />
          </Route>
          <Route path="peliculas">
            <Route
              path="listado"
              index
              element={<Contenido tipo="tpmv" titulo="Peliculas" />}
            />
            <Route
              path="listado/nuevo"
              index
              element={<ContenidoNuevo tipo="tpmv" />}
            />
            <Route
              path="listado/:id"
              index
              element={<ContenidoEdit tipo="tpmv" />}
            />
            <Route
              path="random"
              index
              element={<Random tipo={"tpmv"} nombre={"Peliculas"} />}
            />
            <Route
              path="random/nuevo"
              index
              element={<RandomNuevo tipo={"tpmv"} />}
            />
            <Route path="random/:id" index element={<RandomEdit tipo={"tpmv"}/>} />
          </Route>
          <Route path="series">
            <Route
              path="listado"
              index
              element={<Contenido tipo="tpsr" titulo="Series" />}
            />
            <Route
              path="listado/nuevo"
              index
              element={<ContenidoNuevo tipo="tpsr" />}
            />
            <Route
              path="listado/:id"
              index
              element={<ContenidoEdit tipo="tpsr" />}
            />
            <Route
              path="random"
              index
              element={<Random tipo={"tpsr"} nombre={"Series"} />}
            />
            <Route
              path="random/nuevo"
              index
              element={<RandomNuevo tipo={"tpsr"} />}
            />
            <Route path="random/:id" index element={<RandomEdit tipo={"tpsr"}/>} />
          </Route>
          <Route path="libros">
            <Route
              path="listado"
              index
              element={<Contenido tipo="tplbrs" titulo="Libros" />}
            />
            <Route
              path="listado/nuevo"
              index
              element={<ContenidoNuevo tipo="tplbrs" />}
            />
            <Route
              path="listado/:id"
              index
              element={<ContenidoEdit tipo="tplbrs" />}
            />
            <Route path="autores" index element={<Autores />} />
            <Route path="autores/nuevo" index element={<AutoresNuevo />} />
            <Route path="autores/:id" index element={<AutoresEdit />} />
            <Route
              path="random"
              index
              element={<Random tipo={"tplbrs"} nombre={"Libros"} />}
            />
            <Route
              path="random/nuevo"
              index
              element={<RandomNuevo tipo={"tplbrs"} />}
            />
            <Route path="random/:id" index element={<RandomEdit tipo={"tplbrs"}/>} />
          </Route>
          <Route path="animes">
            <Route
              path="listado"
              index
              element={<Contenido tipo="tpanm" titulo="Animes" />}
            />
            <Route
              path="listado/nuevo"
              index
              element={<ContenidoNuevo tipo="tpanm" />}
            />
            <Route
              path="listado/:id"
              index
              element={<ContenidoEdit tipo="tpanm" />}
            />
            <Route
              path="random"
              index
              element={<Random tipo={"tpanm"} nombre={"Animes"} />}
            />
            <Route
              path="random/nuevo"
              index
              element={<RandomNuevo tipo={"tpanm"} />}
            />
            <Route path="random/:id" index element={<RandomEdit tipo={"tpanm"} />} />
          </Route>
          <Route path="juegos">
            <Route
              path="listado"
              index
              element={<Contenido tipo="tpjg" titulo="Juegos" />}
            />
            <Route
              path="listado/nuevo"
              index
              element={<ContenidoNuevo tipo="tpjg" />}
            />
            <Route
              path="listado/:id"
              index
              element={<ContenidoEdit tipo="tpjg" />}
            />
          </Route>
          <Route path="musica">
            <Route path="artistas" index element={<Artistas />} />
            <Route path="artistas/nuevo" index element={<ArtistasNuevo />} />
            <Route path="artistas/:id" index element={<ArtistasEdit />} />
            <Route path="listado" index element={<Listado />} />
            <Route path="listado/nuevo" index element={<ListadoNuevo />} />
            <Route path="listado/:id" index element={<ListadoEdit />} />
          </Route>
          <Route path="settings">
            <Route path="generos" index element={<Generos />} />
            <Route path="generos/nuevo" index element={<GenerosNuevo />} />
            <Route path="generos/:id" index element={<GenerosEdit />} />

            <Route path="listados" index element={<Listados />} />
            <Route path="listados/nuevo" index element={<ListadosNuevo />} />
            <Route path="listados/:id" index element={<ListadosEdit />} />

            <Route path="tipos" index element={<Tipos />} />
            <Route path="tipos/nuevo" index element={<TiposNuevo />} />
            <Route path="tipos/:id" index element={<TiposEdit />} />

            <Route path="links" index element={<Links />} />
            <Route path="links/:id" index element={<LinksEdit />} />

            <Route path="trello" index element={<Trello />} />

            <Route path="herramientas" index element={<Herramientas />} />
            <Route path="herramientas/nuevo" index element={<HerramientasNuevo />} />
            <Route path="herramientas/:id" index element={<HerramientasEdit />} />

            <Route path="colores" index element={<Colores />} />
            <Route path="colores/nuevo" index element={<ColoresNuevo />} />
            <Route path="colores/:id" index element={<ColoresEdit />} />

            <Route path="usuarios" index element={<Usuarios />} />
            <Route path="usuarios/nuevo" index element={<UsuariosNuevo />} />
            <Route path="usuarios/:id" index element={<UsuariosEdit />} />

            <Route path="permisos" index element={<Permiso />} />
            <Route path="permisos/nuevo" index element={<PermisoNuevo />} />
            <Route path="permisos/:id" index element={<PermisoEdit />} />
          </Route>
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </Router>
  );
};

export default Layout;
