import React, { useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useSelector, useDispatch } from "react-redux";
import "./styles.css";
import ThemeAction from "../../redux/actions/ThemeAction";
import { Xmark, CircleInfo } from "../iconos/Iconos";

const ModalSave = (props) => {
  const themeReducer = useSelector((state) => state.ThemeReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    const themeClass = localStorage.getItem("themeMode", "theme-mode-light");
    const colorClass = localStorage.getItem("colorMode", "theme-mode-light");

    dispatch(ThemeAction.setColor(colorClass));
    dispatch(ThemeAction.setMode(themeClass));
  }, [dispatch]);

  return (
    <>
      <Modal
        className={`${themeReducer.mode} ${themeReducer.color}`}
        show={props.show}
        onHide={() => props.onHide("close")}
      >
        <Modal.Header>
          <Modal.Title />
          <div className="iconClose" onClick={() => props.onHide("close")}>
            <Xmark />
          </div>
        </Modal.Header>
        <Modal.Body>
          <CircleInfo size="3x" color="var(--txt-color)"/>
          ¿Estas seguro que quieres cerrar sesion?
        </Modal.Body>
        <Modal.Footer>
          <Button className="btnCancel" onClick={() => props.onHide("close")}>
            Cancelar
          </Button>
          <Button className="btnSave" onClick={() => props.onHide("save")}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ModalSave;
