import { URL } from "../../AppConfig";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";

toast.configure({
  autoClose: 3000,
  draggable: false,
  closeButton: false,
  draggablePercent: 100,
  progressClassName: "ourbar",
  position: "top-left",
  theme: "dark",
});

export const selectFiltros = [
  [
    {
      value: 1,
      label: "Si",
    },
    {
      value: 2,
      label: "No",
    },
  ],
];

export const selectColumna = [
  [
    {
      value: "1",
      label: "Para hacer",
    },
    {
      value: "2",
      label: "En proceso",
    },
    {
      value: "3",
      label: "Completado",
    },
    {
      value: "4",
      label: "QA",
    },
  ],
];

export const selectTipoPermiso = [
  [
    {
      value: "1",
      label: "Menu",
    },
    {
      value: "2",
      label: "Submenu",
    },
    {
      value: "3",
      label: "Botones",
    },
    {
      value: "4",
      label: "Tab",
    },
    {
      value: "5",
      label: "Tab accion",
    }
  ],
];

export const saveInfo = (url) => {
  const main = async (url) => {
    try {
      const data = await axios.get(`${URL}${url}`);
      return data.data;
    } catch (err) {
      console.error(err);
    }
  };
  return main(url);
};

export const searchInfo = (url, array) => {
  const main = async (url, array) => {
    try {
      const marcas1 = { method: "SEARCH", token: localStorage.getItem("jwt") };
      const marcas2 = array;
      const data = await axios.post(`${URL}${url}`, {
        ...marcas1,
        ...marcas2,
      });
      return data.data;
    } catch (err) {
      console.error(err);
    }
  };
  return main(url, array);
};

export const page = (url, e, array) => {
  const main = async (url, e, array) => {
    try {
      const marcas1 = { method: "SEARCH", token: localStorage.getItem("jwt") };
      const marcas2 = array;
      const data = await axios.post(`${URL}${url}?page=${e}`, {
        ...marcas1,
        ...marcas2,
      });
      return data.data;
    } catch (err) {
      console.error(err);
    }
  };
  return main(url, e, array);
};

export const deleteRow = (url, id, url2 = "", idInt = "", array = null, alert = true) => {
  const main = async (url, id) => {
    try {
      const marcas1 = { method: "DELETE", token: localStorage.getItem("jwt") };
      const marcas2 = id;

      const data = await axios.post(`${URL}${url}`, {
        ...marcas1,
        ...marcas2,
      });

      if (data.data.status === "ok") {
        if(alert){
          toast.success("Registro eliminado con exito", {
            theme: "dark",
            position: "top-left",
          });
        }
        if (idInt) {
          return searchInfo(url, array);
        } else {
          const data = await axios.get(`${URL}${url2 || url}`);
          return data.data;
        }
      } else {
        console.log("Error");
      }
    } catch (err) {
      console.error(err);
    }
  };
  return main(url, id);
};

export const getId = (url, id) => {
  const main = async (url, id) => {
    try {
      const data = await axios.get(`${URL}${url}?id=${id}`);
      return data.data.result;
    } catch (err) {
      console.error(err);
    }
  };
  return main(url, id);
};

export const saveRow = (url, id, metodo, alert = true) => {
  const main = async (url, id) => {
    try {
      const marcas1 = { method: metodo, token: localStorage.getItem("jwt") };
      const marcas2 = id;
      const data = await axios.post(`${URL}${url}`, {
        ...marcas1,
        ...marcas2,
      });

      if (data.data.status === "error") {
        toast.error(data.data.result.error_msg, {
          theme: "dark",
          position: "top-left",
        });
      } else if (data.data.status === "ok") {
        if(alert){
          toast.success("Registro guardado con exito", {
            theme: "dark",
            position: "top-left",
          });
        }
        return data.data.result;
      } else {
        console.log("Error");
      }
    } catch (err) {
      console.error(err);
      toast.error(err.response.data.result.error_msg);
    }
  };
  return main(url, id);
};

export const file = (url, campos) => {
  const main = async (url, campos) => {
    try {
      const marcas1 = { method: "FILE", token: localStorage.getItem("jwt") };
      const marcas2 = campos;
      const data = await axios.post(`${URL}${url}`, {
        ...marcas1,
        ...marcas2,
      });

      if (data.data.result) {
        toast.success("Archivo creado con exito", {
          theme: "dark",
          position: "top-left",
        });
      } else {
        console.log("error");
      }
      return data.data;
    } catch (err) {
      console.error(err);
    }
  };
  return main(url, campos);
};

export const listJson = (url) => {
  const main = async (url) => {
    try {
      const data = await axios.get(`${URL}json/${url}.json`);
      return data;
    } catch (err) {
      console.error(err);
    }
  };
  return main(url);
};

export const listTipos = (list, e) => {
  let approved = list.filter((student) => student.listadoLlave === e);
  var roots = approved.map(function(num) {
    return {
      value: num.idTipo,
      label: num.tipoNombre,
      type: num.tipoLlave,
    };
  });
  return roots;
};

export const listColores = (list, e) => {
  let approved = list.filter((student) => student.listadoLlave === e);
  var roots = approved.map(function(num) {
    return {
      value: num.idTipo,
      label: num.tipoNombre,
      type: num.tipoLlave,
      color: num.tipoColor,
    };
  });
  return roots;
};

export const listScan = (list) => {
  var roots = list.map(function(num) {
    return {
      value: num.idScan,
      label: num.scanNombre,
    };
  });
  return roots;
};

export const listGeneros = (list) => {
  var roots = list.map(function(num) {
    return {
      value: num.idGenero,
      label: num.generoNombre,
    };
  });
  return roots;
};

export const listListado = (list) => {
  var roots = list.map(function(num) {
    return {
      value: num.idListado,
      label: num.listadoNombre,
      type: num.listadoLlave,
    };
  });
  return roots;
};

export const listAutores = (list) => {
  var roots = list.map(function(num) {
    return {
      value: num.idAutor,
      label: num.autorNombre,
    };
  });
  return roots;
};

export const listCC = (list) => {
  var roots = list.map(function(num) {
    return {
      value: num.idCC,
      label: num.ccNombre,
    };
  });
  return roots;
};

export const listTiendas = (list) => {
  var roots = list.map(function(num) {
    return {
      value: num.idTienda,
      label: num.tiendaNombre,
    };
  });
  return roots;
};

export const listSprints = (list) => {
  var roots = list.map(function(num) {
    return {
      value: num.idSprint,
      label: num.sprintNombre,
    };
  });
  return roots;
};

export const listMainMenu = (list) => {
  var roots = list.map(function(num) {
    return {
      value: num.idPermiso,
      label: num.permisoNombre,
    };
  });
  return roots;
};

export const getRelacion = (url, id, base, type = null) => {
  const main = async (url) => {
    try {
      const marcas1 = {
        method: "REL",
        token: localStorage.getItem("jwt"),
        id: id,
        base: base,
        type: type,
      };
      const data = await axios.post(`${URL}${url}`, {
        ...marcas1,
      });
      return data;
    } catch (err) {
      console.error(err);
    }
  };
  return main(url);
};

export const updateCount = (url, id, base) => {
  const main = async (url, id) => {
    try {
      const marcas1 = {
        method: "REL",
        token: localStorage.getItem("jwt"),
        id: id,
        base: base,
      };
      const data = await axios.post(`${URL}${url}`, {
        ...marcas1,
      });
      if (data.data.status === "error") {
        toast.error(data.data.result.error_msg, {
          theme: "dark",
          position: "top-left",
        });
      } else if (data.data.status === "ok") {
        toast.success("Registro actualizado con exito", {
          theme: "dark",
          position: "top-left",
        });
        return data.data.result;
      } else {
        console.log("Error");
      }
    } catch (err) {
      console.error(err);
      toast.error(err.response.data.result.error_msg);
    }
  };
  return main(url, id);
};

export const handleClick = (e) => {
  switch (e) {
    case "tpmng":
      return "mangas";
    case "tpanm":
      return "animes";
    case "tpsr":
      return "series";
    case "tpmv":
      return "peliculas";
    case "tplbrs":
      return "libros"
    case "tpjg":
      return "juegos"
    default:
      return "";
  }
};

export const getValue = (listado, valor) => {
  if (listado && valor) {
    const valorLis = listado[0];
    const resultado = (valorLis || []).find((fruta) => fruta.value === valor);
    return resultado;
  }
  return;
};

export const getValueFull = (fields, fieldsSelect, array) => {
  const result =
    fields && (fieldsSelect === null || fieldsSelect === undefined)
      ? getValue(array, fields)
      : fieldsSelect === null || fieldsSelect === undefined
      ? ""
      : fieldsSelect;
  return result;
};

const campos = {
  tpsr: {
    contenidoImagen: "",
    contenidoLike: "",
    contenidoTerminado: "",
    contenidoPc: "",
    contenidoNombre: "",
    contenidoOrden: "",
    contenidoTipo: "",
    contenidoEstado: "",
    contenidoFechaEstreno: "",
    contenidoFechaFinal: "",
    contenidoSinopsis: "",
    contenidoTipoCnt: "tpsr",
    contenidoGeneros: "",
    contenidoNombres: "",
    contenidoPlataformas: "",
  },
  tplbrs: {
    contenidoImagen: "",
    contenidoAdulto: "",
    contenidoTerminado: "",
    contenidoCraft: "",
    contenidoPlastico: "",
    contenidoLike: "",
    contenidoPc: "",
    contenidoNombre: "",
    contenidoOrden: "",
    contenidoFechaPublicacion: "",
    contenidoEditorial: "",
    contenidoFormato: "",
    contenidoPaginas: "",
    contenidoPlataforma: "",
    contenidoSinopsis: "",
    contenidoTipoCnt: "tplbrs",
    contenidoGeneros: "",
    contenidoPlataformas: "",
    contenidoAutores: "",
  },
  tpjg: {
    contenidoNombre: "",
    contenidoCodigo: "",
    contenidoOrden: "",
    contenidoImagen: "",
    contenidoSinopsis: "",
    contenidoTerminado: "",
    contenidoXbox: "",
    contenidoPc: "",
    contenidoAtari: "",
    contenidoLike: "",
    contenidoTipoCnt: "tpjg",
  },
  tpmv: {
    contenidoNombre: "",
    contenidoSinopsis: "",
    contenidoFechaEstreno: "",
    contenidoImagen: "",
    contenidoLike: "",
    contenidoTerminado: "",
    contenidoPc: "",
    contenidoTipoCnt: "tpmv",
    contenidoOrden: "",
    contenidoGeneros: "",
    contenidoNombres: "",
    contenidoPlataformas: "",
  },
  tpanm: {
    contenidoNombre: "",
    contenidoTipo: "",
    contenidoSubTipo: "",
    contenidoSinopsis: "",
    contenidoFechaEstreno: "",
    contenidoFechaFinal: "",
    contenidoImagen: "",
    contenidoLike: "",
    contenidoTerminado: "",
    contenidoAdulto: "",
    contenidoDemografia: "",
    contenidoEstado: "",
    contenidoPc: "",
    contenidoTipoCnt: "tpanm",
    contenidoOrden: "",
    contenidoGeneros: "",
    contenidoNombres: "",
    contenidoPlataformas: "",
  },
  tpmng: {
    contenidoNombre: "",
    contenidoTipo: "",
    contenidoSinopsis: "",
    contenidoFechaEstreno: "",
    contenidoFechaFinal: "",
    contenidoImagen: "",
    contenidoLike: "",
    contenidoTerminado: "",
    contenidoAdulto: "",
    contenidoDemografia: "",
    contenidoEstado: "",
    contenidoPc: "",
    contenidoTipoCnt: "tpmng",
    contenidoOrden: "",
    contenidoGeneros: "",
    contenidoNombres: "",
    contenidoPlataformas: "",
    contenidoScan: "",
  },
};

export const tabs = {
  tpsr: [
    "general",
    "generos",
    "plataformas",
    "escenas",
    "idiomas",
    "musica",
    "nombres",
    "relacionados",
  ],
  tplbrs: ["general", "generos", "relacionados"],
  tpjg: ["general", "generos", "relacionados"],
  tpmv: [
    "general",
    "generos",
    "plataformas",
    "escenas",
    "idiomas",
    "nombres",
    "musica",
    "relacionados",
  ],
  tpanm: [
    "general",
    "generos",
    "plataformas",
    "escenas",
    "idiomas",
    "nombres",
    "musica",
    "relacionados",
  ],
  tpmng: [
    "general",
    "generos",
    "plataformas",
    "escenas",
    "nombres",
    "scan",
    "relacionados",
  ],
};

export const getInput = (tipo, campo) => {
  return campos[tipo][campo] !== undefined ? true : false;
};

export const getCampos = (tipo) => {
  return campos[tipo];
};

export const getTab = (tipo, tab) => {
  return tabs[tipo].find((dato) => dato === tab);
};

export const monthNames = [
  "Enero",
  "Febrero",
  "Marzo",
  "Abril",
  "Mayo",
  "Junio",
  "Julio",
  "Agosto",
  "Septiembre",
  "Octubre",
  "Moviembre",
  "Diciembre",
];

export const fieldsSelected = {
  contenidoEstado: "",
  contenidoTipo: "",
  contenidoTerminado: "",
  contenidoLike: "",
  contenidoGeneros: "",
  contenidoNombres: "",
  contenidoPlataformas: "",
  contenidoSinopsis: "",
  contenidoImagen: "",
  contenidoCraft: "",
  contenidoPlastico: "",
  contenidoFormato: "",
  contenidoXbox: "",
  contenidoAtari: "",
  contenidoPc: "",
  contenidoAutores: "",
  contenidoSubTipo: "",
  contenidoScan: "",
  contenidoDemografia: "",
  contenidoAdulto: "",
};

export const fieldsClear = {
  contenidoNombre: "",
  contenidoEstado: "",
  contenidoTipo: "",
  contenidoTerminado: "",
  contenidoLike: "",
  contenidoGeneros: "",
  contenidoNombres: "",
  contenidoPlataformas: "",
  contenidoSinopsis: "",
  contenidoImagen: "",
  contenidoCraft: "",
  contenidoPlastico: "",
  contenidoFormato: "",
  contenidoXbox: "",
  contenidoAtari: "",
  contenidoPc: "",
  contenidoAutores: "",
  contenidoSubTipo: "",
  contenidoScan: "",
};

export const validateStringBool = (field, field2, e) => {
  return field === field2 ? (e === "1" ? 2 : 1) : "";
};

export const tableEscena = {
  tpsr: ["Tipo", "Temporada", "Capitulo", "Descripcion", "Minuto", ""],
  tpmv: ["Tipo", "Descripcion", "Minuto", ""],
  tpanm: ["Tipo", "Temporada", "Capitulo", "Descripcion", "Minuto", ""],
  tpmng: ["Tipo", "Capitulo", "Descripcion", ""],
};

export const camposFile = {
  centrocomercial: ["idCC", "ccNombre"],
  diarias: ["idDiarias", "diariasNombre"],
  productos: ["idProducto", "productoNombre"],
  productocomparacion: ["idProductoComparacion"],
  tiendas: ["idTienda", "tiendaNombre"],
  tiendaredes: ["idTiendaRedes"],
  autor: ["idAutor", "autorNombre"],
  contenido: ["idContenido", "contenidoNombre"],
  escena: ["idEscenaContenido", "escenaContenidoContenido"],
  idiomacontenido: ["idIdiomaContenido", "idiomaContenidoContenido"],
  tituloaltcontenido: ["idTituloContenido", "tituloContenidoContenido"],
  musicacontenido: ["idMusica", "musicaContenidoContenido"],
  urlcontenido: ["idPlataforma", "plataformaContenido"],
  scancontenido: ["idScanContenido", "scanContenido"],
  scan: ["idScan", "scanNombre"],
  scanredes: ["idScanRedes"],
  artista: ["idArtista", "artistaNombre"],
  musica: ["idMusica", "musicaCancion"],
  random: ["idRandom", "randomNombre"],
  generos: ["idGenero", "generoNombre"],
  links: ["idPlataforma", "plataformaNombre"],
  listado: ["idListado", "listadoNombre", "listadoLlave"],
  tipo: [
    "idTipo",
    "tipoNombre",
    "tipoListado",
    "listadoLlave",
    "tipoLink",
    "tipoColor",
    "tipoImagen",
    "tipoLlave",
  ],
  todo: ["idTodo", "todoNombre"],
  colores: ["idColores", "coloresColores", "clrP.tipoColor as PrincipalColor"],
  usuarios: ["idUsuario", "usuarioNombre"],
  permisos: ["idPermiso", "permisoNombre"],
};

export const camposCount = {
  tituloaltcontenido: ["contenido", "generatecountnombre"],
  urlcontenido: ["contenido", "generatecountplataforma"],
  scancontenido: ["contenido", "generatecountscan"],
};

export const camposFiltros = {
  escena: ["escenaContenidoContenido"],
  idiomacontenido: ["idiomaContenidoContenido"],
  tituloaltcontenido: ["tituloContenidoContenido"],
  musicacontenido: ["musicaContenidoContenido"],
  urlcontenido: ["plataformaContenido"],
  scancontenido: ["scanContenido"],
};

export const camposFields = {
  productos: {
    img: "productoImagen",
    list: "listado",
  },
  tiendas: {
    img: "tiendaImagen",
    list: "tiendas",
  },
  centrocomercial: {
    list: "centrocomercial",
  },
  diarias: {
    list: "diarias",
  },
  autor: {
    img: "autorImagen",
    list: "autores",
  },
  contenido: {
    img: "contenidoImagen",
    list: "listado",
  },
  scan: {
    img: "scanImagen",
    list: "scans",
  },
  artista: {
    img: "artistaImagen",
    list: "artistas",
  },
  musica: {
    list: "listado",
  },
  random: {
    list: "random",
  },
  generos: {
    list: "generos",
  },
  links: {
    img: "contenidoImagen",
    list: "links",
  },
  listado: {
    list: "listados",
  },
  tipo: {
    img: "tipoImagen",
    list: "tipos",
  },
  colores: {
    list: "colores",
  },
  usuarios: {
    list: "usuarios",
  },
  permisos: {
    list: "permisos",
  },
};

export const listadoRel = {
  artista: {
    busqueda: "artistaNombre",
    saveURL: "musicaartista",
    campoE: "musicaArtistaArtista",
    campoID: "musicaArtistaMusica",
    baseURL: "musica",
    idDel: "idMusicaArtista",
  },
  autor: {
    busqueda: "autorNombre",
    saveURL: "contenidoautor",
    campoE: "contenidoAutorAutor",
    campoID: "contenidoAutorContenido",
    baseURL: "contenido",
    idDel: "idContenidoAutor",
  },
};

export const validate = (e, text) => {
  if (e === "" || e === undefined) {
    toast.error(text + " es obligatorio", {
      theme: "dark",
      position: "top-left",
    });
    return true;
  }
};

export const validateN = (e, text) => {
  if (e === "" || isNaN(e)) {
    toast.error(text + " es obligatorio", {
      theme: "dark",
      position: "top-left",
    });
    return true;
  }
};

export const list = (string) => {
  if (string != null) {
    var myArray = string.split("//");
    const listItems = myArray.map((number) => {
      var myArrayNew = number.split(",");
      return <li key={myArrayNew[1]}>{myArrayNew[0]}</li>;
    });
    return listItems;
  }
  return;
};

export const listLink = (string) => {
  if (string) {
    var myArray = string.split("//");
    const listItems = myArray.map((number) => {
      var myArrayNew = number.split(",");
      return (
        <li key={myArrayNew[1]}>
          <Link to={`../artistas/${myArrayNew[1].trim()}`}>
            {myArrayNew[0]}
          </Link>
        </li>
      );
    });
    return listItems;
  }
  return;
};

export const valNull = (e) => {
  return e === null ? "" : e.value;
};

export const valNullC = (e) => {
  return e === null ? "" : e;
};

export const tipos = {
  tpsr: "sre",
  tpanm: "anm",
  tpmng: "mng",
};

export const plataformas = {
  tpsr: "plt_anm",
  tplbrs: "pltfrm_lbrs",
  tpanm: "plt_anm",
  tpmng: "plt",
  tpmv: "plt_anm",
};

export const removeAccents = (str) => {
  return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
};

export const removeVolcals = (str) => {
  return str
    .replace(/[aeiou]/gi, "")
    .replace(/[^a-zA-Z0-9 ]/g, "")
    .replaceAll(" ", "_")
    .toLowerCase();
};

export function height(filtro, header){
  let alto = 0;
  let altoH = 0;

  if(header >= 146){
    altoH = 303; 
  }else if(header >= 84){
    altoH = 241; 
  }else{
    altoH = 197; 
  }
  
  if(filtro === 0){
    alto = 0;
  }else{
    alto = filtro;
  }

  return `calc(100vh - ${(alto + altoH)}px)`
}