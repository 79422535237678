import React, { Suspense, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { listJson } from "../redux/routes/index.jsx";
import { Outlet } from "react-router-dom";

import Sidebar from "../components/sidebar/Sidebar";
import ThemeAction from "../redux/actions/ThemeAction";
import UserAction from "../redux/actions/UserAction";

import { useNavigate } from "react-router-dom";

import Login from "../pages/Login";

const AllRoutes = () => {
  const navigate = useNavigate();
  const themeReducer = useSelector((state) => state.ThemeReducer);
  const userReducer = useSelector((state) => state.UserReducer);

  const dispatch = useDispatch();

  const [isLogin, setIsLogin] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const themeClass = localStorage.getItem("themeMode", "theme-mode-light");
    const colorClass = localStorage.getItem("colorMode", "theme-mode-light");
    const username = localStorage.getItem("username");

    dispatch(ThemeAction.setColor(colorClass));
    dispatch(ThemeAction.setMode(themeClass));
    dispatch(UserAction.setUsername(username))
  }, [dispatch]);

  useEffect(() => {
    const user = localStorage.getItem("jwt");
    dispatch(UserAction.setUser(user));
    if (user) {
      setIsLogin(true);
      setLoading(true);
      if(userReducer.tipos === undefined){
        listJson("tipo").then((output) => {
          dispatch(UserAction.getTipos(output.data));
        });
      }
    }else{
      setLoading(true);
    }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return navigate("/");
 // eslint-disable-next-line react-hooks/exhaustive-deps
 },[isLogin]);

  return (
    <>
      <div className={`layout ${themeReducer.mode} ${themeReducer.color}`}>
        {isLogin ? (
          <>
            <Sidebar />
            <div className="layout__content">
              <div className="layout__content-main">
                <Suspense fallback={<h1>Cargando...</h1>}>
                  <Outlet />
                </Suspense>
              </div>
            </div>
          </>
        ) : (
          loading && <Login isLogin={setIsLogin} />
        )}
      </div>
    </>
  );
};

export default AllRoutes;
