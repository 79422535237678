import { useEffect, useState } from "react";
import { getId } from "../redux/routes/index.jsx";
import { useSelector, useDispatch } from "react-redux";
import UserAction from "../redux/actions/UserAction.js";

export const useListPermisos = (params) => {
  const [listado, setListado] = useState([]);
  const dispatch = useDispatch();
  const userReducer = useSelector((state) => state.UserReducer);

  useEffect(() => {
    if (userReducer.permisos === undefined) {
        getId("permisousuario", localStorage.getItem('jwt')).then((output) => {
            dispatch(UserAction.getPermisos(output))
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    if (userReducer.permisos !== undefined) {
      setListado(userReducer.permisos);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userReducer.permisos]);

  return {
    listado
  };
};