const setUser = (user) => {
  return {
    type: "SET_USER",
    payload: user,
  };
};

const getUser = () => {
  return {
    type: "GET_USER",
  };
};

const setUsername = (user) => {
  return {
    type: "SET_USERNAME",
    payload: user,
  };
};

const setLastCap = (cap) => {
  return {
    type: "SET_LASTCAP",
    payload: cap,
  };
};

const getTipos = (tipos) => {
  return {
    type: "GET_TIPOS",
    payload: tipos,
  };
};

const getScan = (scan) => {
  return {
    type: "GET_SCAN",
    payload: scan,
  };
};

const getGeneros = (generos) => {
  return {
    type: "GET_GENEROS",
    payload: generos,
  };
};

const getListado = (listado) => {
  return {
    type: "GET_LISTADO",
    payload: listado,
  };
};

const getVistaManga = (vista) => {
  return {
    type: "GET_VISTA_MANGA",
    payload: vista,
  };
};

const getVistaProducto = (vista) => {
  return {
    type: "GET_VISTA_PRODUCTO",
    payload: vista,
  };
};

const setProductoFusion = (value) => {
  return {
    type: "SET_PRDFUSION",
    payload: value,
  };
};

const getCC = (cc) => {
  return {
    type: "GET_CC",
    payload: cc,
  };
};

const getTiendas = (tiendas) => {
  return {
    type: "GET_TIENDAS",
    payload: tiendas,
  };
};

const getTitulo = (titulo) => {
  return {
    type: "GET_TITULO",
    payload: titulo,
  };
};

const getSprints = (sprints) => {
  return {
    type: "GET_SPRINTS",
    payload: sprints,
  };
};

const getAutores= (generos) => {
  return {
    type: "GET_AUTORES",
    payload: generos,
  };
};

const getFiltros= (filtros) => {
  return {
    type: "GET_FILTROS",
    payload: filtros,
  };
};

const getGoBack= (goback) => {
  return {
    type: "GET_GOBACK",
    payload: goback,
  };
};

const getColores = (colores) => {
  return {
    type: "GET_COLORES",
    payload: colores,
  };
};

const getMainMenu = (main_menu) => {
  return {
    type: "GET_MAIN_MENU",
    payload: main_menu,
  };
};

const getMenu = (menu) => {
  return {
    type: "GET_MENU",
    payload: menu,
  };
};

const getPermisos = (permisos) => {
  return {
    type: "GET_PERMISOS",
    payload: permisos,
  };
};

const exportDefault = {
  setUser,
  getUser,
  setUsername,
  setLastCap,
  getTipos,
  getScan,
  getGeneros,
  getListado,
  getVistaManga,
  getCC,
  getTiendas,
  getVistaProducto,
  setProductoFusion,
  getTitulo,
  getSprints,
  getAutores,
  getFiltros,
  getGoBack,
  getColores,
  getMainMenu,
  getMenu,
  getPermisos
};

export default exportDefault;
