import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faSave,
  faTimes,
  faTrash,
  faPen,
	faEye,
	faFire,
  faCheck,
  faComputer,
  faGamepad,
  faXmark,
	faC,
	faP,
	faSearch,
	faMountainSun, 
	faCircleInfo,
  faComment,
  faArchive,
  faFilter,
	faLocationDot, 
	faDollar,
	faImage,
	faEyeSlash,
	faPlus,
	faChevronRight, 
	faRotate,
  faPlay
} from "@fortawesome/free-solid-svg-icons";

export const ChevronRight = ({ size = null, color="white" }) => {
  return <FontAwesomeIcon size={size} color={color} icon={faChevronRight} />;
};

export const Rotate = ({ size = null, color="white" }) => {
  return <FontAwesomeIcon size={size} color={color} icon={faRotate} />;
};

export const Times = ({ size = null, color="white" }) => {
  return <FontAwesomeIcon size={size} color={color} icon={faTimes} />;
};

export const Save = ({ size = null, color="white" }) => {
  return <FontAwesomeIcon size={size} color={color} icon={faSave} />;
};

export const ArrowLeft = ({ size = null, color="white" }) => {
  return <FontAwesomeIcon size={size} color={color} icon={faArrowLeft} />;
};

export const Trash = ({ size = null, color="white" }) => {
  return <FontAwesomeIcon size={size} color={color} icon={faTrash} />;
};

export const Pen = ({ size = null, color="white" }) => {
  return <FontAwesomeIcon size={size} color={color} icon={faPen} />;
};

export const Eye = ({ size = null, color="white" }) => {
  return <FontAwesomeIcon size={size} color={color} icon={faEye} />;
};

export const Fire = ({ size = null, color="white" }) => {
  return <FontAwesomeIcon size={size} color={color} icon={faFire} />;
};

export const Check = ({ size = null, color="white" }) => {
  return <FontAwesomeIcon size={size} color={color} icon={faCheck} />;
};

export const Computer = ({ size = null, color="white" }) => {
  return <FontAwesomeIcon size={size} color={color} icon={faComputer} />;
};

export const Gamepad = ({ size = null, color="white" }) => {
  return <FontAwesomeIcon size={size} color={color} icon={faGamepad} />;
};

export const Xmark = ({ size = null, color="white" }) => {
  return <FontAwesomeIcon size={size} color={color} icon={faXmark} />;
};

export const C = ({ size = null, color="white" }) => {
  return <FontAwesomeIcon size={size} color={color} icon={faC} />;
};

export const P = ({ size = null, color="white" }) => {
  return <FontAwesomeIcon size={size} color={color} icon={faP} />;
};

export const Search = ({ size = null, color="white" }) => {
  return <FontAwesomeIcon size={size} color={color} icon={faSearch} />;
};

export const MountainSun = ({ size = null, color="white" }) => {
  return <FontAwesomeIcon size={size} color={color} icon={faMountainSun} />;
};

export const CircleInfo = ({ size = null, color="white" }) => {
  return <FontAwesomeIcon size={size} color={color} icon={faCircleInfo} />;
};

export const Comment = ({ size = null, color="white" }) => {
  return <FontAwesomeIcon size={size} color={color} icon={faComment} />;
};

export const Archive = ({ size = null, color="white" }) => {
  return <FontAwesomeIcon size={size} color={color} icon={faArchive} />;
};

export const Filter = ({ size = null, color="white" }) => {
  return <FontAwesomeIcon size={size} color={color} icon={faFilter} />;
};

export const LocationDot = ({ size = null, color="white" }) => {
  return <FontAwesomeIcon size={size} color={color} icon={faLocationDot} />;
};

export const Dollar = ({ size = null, color="white" }) => {
  return <FontAwesomeIcon size={size} color={color} icon={faDollar} />;
};

export const Image = ({ size = null, color="white" }) => {
  return <FontAwesomeIcon size={size} color={color} icon={faImage} />;
};

export const EyeSlash = ({ size = null, color="white" }) => {
  return <FontAwesomeIcon size={size} color={color} icon={faEyeSlash} />;
};

export const Plus = ({ size = null, color="white" }) => {
  return <FontAwesomeIcon size={size} color={color} icon={faPlus} />;
};

export const Play = ({ size = null, color="white" }) => {
  return <FontAwesomeIcon size={size} color={color} icon={faPlay} />;
};