import React, { useRef, useState } from "react";
import { URL } from "../AppConfig";
import UserAction from '../redux/actions/UserAction'
import { toast } from "react-toastify";
import LoaderButton from "../components/loaderButton"
import { useDispatch } from "react-redux";
import { listJson, getId } from "../redux/routes/index.jsx";
import "./login.css"
toast.configure();

const Login = (props) => {
  const dispatch = useDispatch()

  const [showPass, setShowPass] = useState(false)
  const [loading, setLoading] = useState(false)

  const refUser = useRef(null);
  const refPass = useRef(null);

  const enviarData = async (url, data) => {
    const respuesta = await fetch(url, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    });

    const json = await respuesta.json();
    return json;
  };

  const handleKeyPress = (e)  => {
    if(e.key === 'Enter'){
      handleLogin()
    }
  }

  const handleLogin = async () => {
    const data = {
      usuario: refUser.current.value,
      password: refPass.current.value,
    };
    setLoading(true)
		const resp = await enviarData(URL+"/auth", data)
    
		if(resp.status === 'ok')
		{
			props.isLogin(true);
      localStorage.setItem('jwt', resp.result.token)
      localStorage.setItem('username', refUser.current.value)
      dispatch(UserAction.setUser(resp.result.token))
      dispatch(UserAction.setUsername(refUser.current.value))
      
      getId("permisousuario", resp.result.token).then((output) => {
        if(output){
          dispatch(UserAction.getPermisos(output.data))
        }
      });
    
      listJson("tipo").then((output) => {
        if(output){
          dispatch(UserAction.getTipos(output.data));
        }
      });

		}else{
      toast.error(resp.result.error_msg, { theme: "dark", position: 'top-left' });
      setLoading(false)
		}
  };

  return (
    <div className={`containerLogin ${loading ? "buttonPrc" : ""}`}>
      <div className="cntLogin">
        <div className="formLogin">
            <>
              <h1 style={{ color: "var(--main-color)" }}>Iniciar sesión</h1>
              <input
                onKeyUp={(e) => handleKeyPress(e)}
                placeholder="Usuario"
                style={{ border: "1px solid var(--main-color)" }}
                type="text"
                ref={refUser}
              />
              <input
                onKeyUp={(e) => handleKeyPress(e)}
                placeholder="Contraseña"
                style={{ border: "1px solid var(--main-color)" }}
                type={showPass ? "text" : "password"}
                ref={refPass}
              />
              <span className="showPass" onClick={() => setShowPass(!showPass)}>
                {showPass ? "Ocultar" : "Mostrar"} contraseña
              </span>
              <button onClick={handleLogin}>
                {loading ? <LoaderButton /> : "Ingresar"}
              </button>
            </>  
        </div>
        <div className="textLogin">
            <>
              <p>Bienvenido a CRM Lyan</p>
              <span>Ingresa para administrar tus hobbies</span> 
            </>
        </div>
      </div>
    </div>
  );
};

export default Login;
