import React, { useState } from "react";
import "./topnav.css";
import ThemeMenu from "../thememenu/ThemeMenu";
import user_image from "../../assets/images/user.jpg";
import ModalLogout from "../modals/ModalLogout";
import { useSelector } from "react-redux";

const TopNav = () => {
  const userReducer = useSelector((state) => state.UserReducer);

  const curr_user = {
    display_name: userReducer.username,
    image: user_image,
  };

  const [show, setShow] = useState(false);

  const Logout = (e) => {
    setShow(false);
    if (e === "save") {
      localStorage.removeItem("jwt");
      window.location.reload(false);
    }
  };

  return (
    <div className="topnav">
      <ModalLogout show={show} onHide={Logout} />
      <div className="topnav__right">
        <div className="topnav__right-item">
          <div className="sidebar__item">
            <div className="sidebar__item-inner ">
              <div className="topnav__right-user">
                <div className="topnav__right-user__image">
                  <img src={curr_user.image} alt="" />
                </div>
                <div className="topnav__right-user__name">
                  {curr_user.display_name}
                </div>
              </div>
            </div>
            <div className="sublist">
                <div className="sidebar__subitem">
                  <div className="sidebar__subitem-inner">
                    <div onClick={() => setShow(true)}>
                      <div className="notification-item">
                        <i className={"bx bx-log-out-circle bx-rotate-180"} />
                        <span>Logout</span>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
          </div>
        </div>
        <div className="topnav__right-item">
          <ThemeMenu />
        </div>
      </div>
    </div>
  );
};

export default TopNav;
