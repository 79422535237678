import React, { useRef, useState } from "react";
import "./responsivemenu.css";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import ThemeMenuColor from "../thememenu/ThemeMenu";
import ModalLogout from "../modals/ModalLogout";
import { useListMainMenuAll } from "../../hooks/useListMainMenuAll";
import { useListPermisos } from "../../hooks/useListPermisos.js";
import { useSelector } from "react-redux";
import user_image from "../../assets/images/user.jpg";

const clickOutsideRef = (menu_ref, menu_toggle_ref) => {
  document.addEventListener("mousedown", (e) => {
    if (menu_toggle_ref.current && menu_toggle_ref.current.contains(e.target)) {
      menu_ref.current.classList.toggle("active");
    } else if (menu_ref.current && !menu_ref.current.contains(e.target)) {
      menu_ref.current.classList.remove("active");
    }
  });
};

const ThemeMenu = () => {
  const [subMenu, setSubMenu] = useState([]);
  const [subMenuActive, setSubMenuActive] = useState(false);
  const [show, setShow] = useState(false);
  const { listado } = useListMainMenuAll();
  const menu_ref = useRef(null);
  const menu_toggle_ref = useRef(null);
  const prm = useListPermisos();

  const location = useLocation();
  const activeItem = listado.findIndex(
    (item) => item.firstMenu === location.pathname.split("/")[1]
  );

  clickOutsideRef(menu_ref, menu_toggle_ref);

  const setActiveMenu = () => menu_ref.current.classList.add("active");
  const closeMenu = () => menu_ref.current.classList.remove("active");

  const closeSubMenu = () => {
    setSubMenuActive(false);
    setSubMenu([]);
  };

  const SidebarItem = (props) => {
    const { listado } = useListMainMenuAll();
    const active = props.active ? "active" : "";

    const clickMenu = (e) => {
      const found = listado.find((obj) => {
        return obj.id === e;
      });
      setSubMenu(found.sublist);
      setSubMenuActive(true);
    };

    return (
      <div className="" key={props.id}>
        {props.route !== "" ? (
          <Link to={props.route} key={`${props.id}`} onClick={() => closeMenu()}>
            <div className={`sidebar__item-inner ${active}`}>
              {props.icono && <i className={props.icono} />}
              <span>{props.title}</span>
            </div>
          </Link>
        ) : (
          <div
            onClick={() => clickMenu(props.id)}
            className={`sidebar__item-inner ${active}`}
          >
            {props.icono && <i className={props.icono} />}
            <span>{props.title}</span>
          </div>
        )}
      </div>
    );
  };

  const Logout = (e) => {
    setShow(false);
    if (e === "save") {
      localStorage.removeItem("jwt");
      window.location.reload(false);
    }
  };

  const userReducer = useSelector((state) => state.UserReducer);

  const curr_user = {
    display_name: userReducer.username,
    image: user_image,
  };

  return (
    <div style={{ display: "flex", gap: 15, alignItems: "center" }}>
      <ModalLogout show={show} onHide={Logout} />
      <div className="sidebar__item-inner ">
        <div className="topnav__right-user">
          <div className="topnav__right-user__image">
            <img src={curr_user.image} alt="" />
          </div>
          <div className="topnav__right-user__name">
            {curr_user.display_name}
          </div>
        </div>
      </div>
      <button
        ref={menu_toggle_ref}
        className="dropdown__toggle"
        onClick={() => setActiveMenu()}
      >
        <i className="bx bx-menu" />
      </button>
      <button className="dropdown__toggle" onClick={() => setShow(true)}>
        <i className="bx bx-log-out-circle bx-rotate-180" />
      </button>
      <ThemeMenuColor />
      <div ref={menu_ref} className="theme-menu">
        <button
          className="theme-menu__close"
          onClick={() => {
            subMenuActive ? closeSubMenu() : closeMenu();
          }}
        >
          <i className="bx bx-x" />
        </button>
        {subMenu.length > 0
          ? subMenu.map((item, index) => (
              prm.listado.includes(item.id) &&
                <div className="sidebar__item-inner" key={`${item.id}`}>
                  <Link
                    onClick={() => {
                      closeMenu();
                      closeSubMenu();
                    }}
                    to={item.route}
                    key={`${item.id}`}
                  >
                    <div className="sidebar__subitem" key={`${item.id}`}>
                      <div className={`sidebar__subitem-inner`}>
                        <span>{item.display_name}</span>
                      </div>
                    </div>
                  </Link>
                </div>
            ))
          : listado.map((item, index) => (
              prm.listado.includes(item.id) &&
                <SidebarItem
                  title={item.display_name}
                  icono={item.icono}
                  active={index === activeItem}
                  sublist={item.sublist ? item.sublist : []}
                  route={item.route}
                  id={item.id}
                  key={item.id}
                />
            ))}
      </div>
    </div>
  );
};

export default ThemeMenu;
