const UserReducer = (state = "", action) => {
  switch (action.type) {
    case "SET_USER":
      return {
        ...state,
        user: action.payload,
      };
    case "SET_USERNAME":
      return {
        ...state,
        username: action.payload,
      };
    case "SET_LASTCAP":
      return {
        ...state,
        lastcap: action.payload,
      };
    case "GET_TIPOS":
      return {
        ...state,
        tipos: action.payload,
      };
    case "GET_SCAN":
      return {
        ...state,
        scan: action.payload,
      };
    case "GET_GENEROS":
      return {
        ...state,
        generos: action.payload,
      };
    case "GET_LISTADO":
      return {
        ...state,
        listado: action.payload,
      };
    case "GET_VISTA_MANGA":
      return {
        ...state,
        vista: action.payload,
      };
    case "SET_CONTPRIN":
      return {
        ...state,
        principal: action.payload,
      };
    case "SET_PRDFUSION":
      return {
        ...state,
        fusion: action.payload,
      };
    case "GET_CC":
      return {
        ...state,
        cc: action.payload,
      };
    case "GET_TIENDAS":
      return {
        ...state,
        tiendas: action.payload,
      };
    case "GET_VISTA_PRODUCTO":
      return {
        ...state,
        vistaPrd: action.payload,
      };
    case "GET_TITULO":
      return {
        ...state,
        titulo: action.payload,
      };
    case "GET_SPRINTS":
      return {
        ...state,
        sprints: action.payload,
      };
    case "GET_AUTORES":
      return {
        ...state,
        autores: action.payload,
      };
    case "GET_FILTROS":
      return {
        ...state,
        filtros: action.payload,
      };
    case "GET_GOBACK":
      return {
        ...state,
        goback: action.payload,
      };
    case "GET_COLORES":
      return {
        ...state,
        colores: action.payload,
      };
    case "GET_MAIN_MENU":
      return {
        ...state,
        main_menu: action.payload,
      };
    case "GET_MENU":
      return {
        ...state,
        menu: action.payload,
      };
    case "GET_PERMISOS":
      return {
        ...state,
        permisos: action.payload,
      };
    default:
      return state;
  }
};

export default UserReducer;
